<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g id="Solid"><path d="M256,352c-3.252,9.918-14.97,18.725-32.711,24.246-45.054,14.02-72.928,34.02-78.326,55.754H367.037c-5.4-21.734-33.272-41.734-78.326-55.754C270.97,370.725,259.252,361.918,256,352Z"/><path d="M160.1,128c13.43,25.58,34.93,48.48,63.19,66.55A72.525,72.525,0,0,1,256,244.36a72.525,72.525,0,0,1,32.71-49.81c28.26-18.07,49.76-40.97,63.19-66.55Z"/><path d="M305.949,221.507C363.206,184.9,400,127.959,400,64h0a16,16,0,0,0,16-16V24H96V47a16.985,16.985,0,0,0,16,16.949V64c0,63.959,36.794,120.9,94.051,157.507,25.159,16.086,25.159,52.9,0,68.986C148.794,327.1,112,384.041,112,448v.051A16.985,16.985,0,0,0,96,465v23H416V464a16,16,0,0,0-16-16h0c0-63.959-36.794-120.9-94.051-157.507C280.79,274.407,280.79,237.593,305.949,221.507ZM271.08,256a56.68,56.68,0,0,0,26.25,47.973C352.41,339.188,384,391.685,384,448H128c0-56.315,31.59-108.812,86.67-144.027a56.961,56.961,0,0,0,0-95.946C159.59,172.812,128,120.315,128,64H384c0,56.315-31.59,108.812-86.67,144.027A56.68,56.68,0,0,0,271.08,256Z"/><path d="M256,287.487a7.5,7.5,0,0,0,7.5-7.5V272a7.5,7.5,0,0,0-15,0v7.987A7.5,7.5,0,0,0,256,287.487Z"/><path d="M248.5,319.987a7.5,7.5,0,1,0,15,0V312a7.5,7.5,0,0,0-15,0Z"/></g></svg>
            <h3 class="pl-2 text-uppercase text-lg">End Communication</h3>
          </div>
          <div class="flex">
           <md-button
            v-for="(item, index) in buttonFilters"
            :key="index"
            class="rounded"
            @click="onFilteringData(index)"
            :class="[index == currentIndex ? 'bg-victoria text-white': 'outline-gray-400']"
            >
            {{ item.label }}
           </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
          <SelectComponent
            :items="statuses"
            :placeholder="false"
            return-type="object"
            @getObject="getObject"
            class="w-64 m-1" />
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="item"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="endCommunications" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="endId" md-label="END_ID" class="text-victoria">{{ item.endId }}</md-table-cell>
            <md-table-cell md-sort-by="ptId" md-label="PTE_ID" class="text-victoria">{{ item.ptId }}</md-table-cell>
            <md-table-cell md-sort-by="testName" md-label="TEST NAME">{{ item.testName }}</md-table-cell>
            <md-table-cell md-sort-by="course" md-label="COURSE">{{ item.course }}</md-table-cell>
            <md-table-cell md-sort-by="group" md-label="GROUP">{{ item.group }}</md-table-cell>
            <md-table-cell md-sort-by="createdBy" md-label="CREATED BY">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">edit</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox,
    SelectComponent
    } from "@/components";
import { PromptDetails, EndCommunicationCreate, PromptEdit } from "@/components/molecule";
import { endCommunications } from "@/data/master/qatm/toolkit"
import { paginate } from "@/mixins/paginate";
import { mapMutations } from "vuex";
import cms from "@/data/cms";

export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        EndCommunicationCreate,
        SelectComponent
  },
  data() {
      return {
          currentIndex: 0,
          component: 'EndCommunicationCreate',
          statuses: cms.statuses,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : [
          {id: 2, label: 'Practice Test'},
          {id: 3, label: 'Mock Test'},
          {id: 4, label: 'Quiz'},
        ]
      }
  },
  computed: {
    endCommunications() {
      if(this.selectedValue && this.selectedValue !== 'all') {
          return endCommunications.filter(item => this.toLower(item.status) == this.selectedValue);
      }
      if(this.pagination) {
        return endCommunications.slice(this.paginate.start, this.paginate.end);
      }
      return endCommunications;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return endCommunications.filter(item => this.toLower(item.item).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    getObject(item) {
      this.onPrepareQueryString('type', this.toLower(item.name));
      this.selectedValue = this.toLower(item.name);
    },
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'EndCommunicationCreate';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    onFilteringData(index) {
      this.currentIndex = index;
      this.onPrepareQueryString('filter', this.buttonFilters[index].label);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>