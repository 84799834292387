<template>
  <form @submit.prevent="onSubmit" class="p-6" style="">
    <DialogTitle
      style="margin-left: -10px; margin-right: -10px"
      label="Create New Study Guide"
      align="center"
    />
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Section</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 'reading', label: 'Reading' },
          { id: 'writing', label: 'Writing' },
          { id: 'speaking', label: 'Speaking' },
          { id: 'listening', label: 'Listening' },
        ]"
        v-model="questionSection"
      />
    </div>
    <div class="my-3">
      <SelectComponent
        :items="getQuestionTypes"
        label="Choose Item"
        class="w-74"
        v-model="form.question_type_id"
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Write the study guide</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
      />
    </div>

    <div class="flex flex-col mt-3">
        <label class="text-uppercase inline-block font-bold mb-2">Choose Material</label>
        <div class="flex align-center my-2">
            <FileInputComponent v-model="form.materials[0]" class="basis" />
            <md-button @click="materialCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="flex align-center my-1" v-for="i in materialCount" :key="i">
            <FileInputComponent v-model="form.materials[i]" class="basis" />
            <md-button @click="materialCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
            </md-button>
        </div>
    </div>
    
    <div class="flex flex-col mt-3">
        <label class="text-uppercase inline-block font-bold mb-2">Write important links</label>
        <div class="flex align-center my-2">
            <InputFieldComponent v-model="form.external_links[0]" class="basis" />
            <md-button @click="linkCount++" class="md-icon-button md-dense md-primary">
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <div class="flex align-center my-1" v-for="i in linkCount" :key="i">
            <InputFieldComponent v-model="form.external_links[i]" class="basis" />
            <md-button @click="linkCount--" class="md-icon-button md-dense">
                <md-icon class="text-danger">remove</md-icon>
            </md-button>
        </div>
        
    </div>
    <div class="my-3 flex justify-between">
      <md-checkbox v-model="form.active">Mark as active</md-checkbox>
      <div>
        <md-button
          class="bg-default text-uppercase rounded"
          @click="dialog(false)"
          >Cancel</md-button
        >
        <md-button
          type="submit"
          class="bg-victoria text-white text-uppercase rounded"
        >Save</md-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import FileInputComponent from "@/components/atom/form/FileInputComponent";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    FileInputComponent,
    InputFieldComponent,
    TextEditor,
    SelectComponent,
    DialogTitle,
  },
  data() {
    return {
      form: {
        question_type_id: "",
        title: "",
        materials: [],
        external_links: [],
        active: true
      },
      materialCount: 0,
      linkCount: 0,
      questionSection: ''
    };
  },
  computed: {
      ...mapGetters({
          getQuestionTypes: "question/getQuestionTypes"
      })
  },
  watch: {
      questionSection(value) {
          this.actGetQuestionTypes(`?section=${value}`)
      }
  },
  methods: {
    ...mapActions({
        actGetQuestionTypes: "question/actGetQuestionTypes",
        actSaveStudyGuide: "studyGuide/actSaveStudyGuide",
        actListStudyGuide: "studyGuide/actListStudyGuide"
    }),
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    upload(attachments) {
      let item = [];
      if(attachments.length > 0) {
        attachments.forEach(element => {
        if(element) {
          item.push(element.name)
        }
        });
      }
      return item;
    },
    onSubmit() {
      this.form.materials = this.upload(this.form.materials);
      this.actSaveStudyGuide(this.form)
        .then(({ message }) => {
          this.setShowSnackbar(message);
          this.dialog(false);
          this.actListStudyGuide(``);

        })
    },
  },
  validations: {
    form: {
      item: { required },
      prompt: { required },
      module: { required },
      section: { required }
    },
  },
  mounted() {
    this.actGetQuestionTypes(`?section=reading`)
  }
};
</script>
