<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26" fill="#5B5B5B">
              <path d="M12.34375 1.09375C12.0625 1.109375 11.8125 1.21875 11.625 1.4375L1.28125 12.96875C1.066406 13.214844 0.535156 14.113281 0.28125 15.28125C0.105469 15.773438 0 16.34375 0 17C0 20.101563 2.59375 21.34375 2.59375 21.34375C2.632813 21.367188 2.675781 21.386719 2.71875 21.40625L14.71875 24.96875C15.109375 25.082031 15.53125 24.945313 15.78125 24.625L25.5625 12.84375C25.804688 12.542969 25.855469 12.128906 25.6875 11.78125C25.6875 11.78125 25.308594 10.90625 25.71875 9.90625C25.855469 9.574219 25.804688 9.195313 25.585938 8.910156C25.367188 8.625 25.011719 8.480469 24.65625 8.53125C24.304688 8.585938 24.003906 8.824219 23.875 9.15625C23.363281 10.410156 23.511719 11.363281 23.6875 11.96875L14.65625 22.84375L3.34375 19.5L3.28125 19.46875C3.09375 19.398438 2 18.90625 2 17C2 16.25 2.160156 15.851563 2.3125 15.625C2.464844 15.398438 2.625 15.289063 2.84375 15.21875C3.0625 15.148438 3.3125 15.148438 3.5 15.15625L3.53125 15.15625L13.96875 18.15625C14.058594 18.179688 14.160156 18.1875 14.25 18.1875C14.5625 18.1875 14.863281 18.027344 15.0625 17.78125L25.65625 5.5625C25.886719 5.28125 25.964844 4.90625 25.84375 4.5625C25.726563 4.214844 25.417969 3.953125 25.0625 3.875L12.625 1.09375C12.53125 1.074219 12.4375 1.089844 12.34375 1.09375 Z M 12.0625 5.0625C12.171875 5.058594 12.273438 5.070313 12.375 5.09375L19.8125 6.90625C20.21875 7.003906 20.375 7.269531 20.15625 7.53125L19.34375 8.5C19.121094 8.761719 18.597656 8.878906 18.1875 8.78125L10.78125 6.96875C10.371094 6.871094 10.21875 6.570313 10.4375 6.3125L11.21875 5.375C11.382813 5.179688 11.734375 5.070313 12.0625 5.0625Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Study Guide</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
              v-model="keyword"
              placeholder="Search"
              class="p-2 w-64 m-2"
              @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="studyGuides" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="promptId" md-label="EXP_ID" class="text-victoria">{{ item.guide_id }}</md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.question_type_section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.question_type_title }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Active': 'Inactive' }}</md-table-cell>
            <md-table-cell >
              <span class="flex action">
              <span
                class="rounded px-1 text-white text-base mx-1"
                :class="[toLower((item.active == 1) ? 'Active': 'Inactive') == 'active' ? 'bg-victoria' : 'bg-danger']"
                @click="onChangeStatus(item)">{{ (item.active == 1) ? 'Active': 'Inactive' }}
              </span>
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item.id)">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit(item.id)">edit</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="actDeleteStudyGuide(item.id)">delete</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8" v-if="studyGuides.lenght > 20">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
          />
      </div>
    <Dialog>
      <component :questionTypes="questionTypes" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import StudyGuideShow from "@/components/molecule/question-and-test-management/toolkit/StudyGuideShow";
import StudyGuideCreate from "@/components/molecule/question-and-test-management/toolkit/StudyGuideCreate";
import StudyGuideEdit from "@/components/molecule/question-and-test-management/toolkit/StudyGuideEdit";
import cms from "@/data/cms";
import { query } from "@/utils/queryString";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        StudyGuideShow,
        StudyGuideCreate,
        StudyGuideEdit
  },
  data() {
      return {
          component: 'StudyGuideShow',
          questionTypes: '',
          types: cms.types,
          keyword: '',
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    ...mapGetters({
      studyGuides: "studyGuide/getStudyGuides"
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actListStudyGuide(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actListStudyGuide(`?${query.get()}`);
        }
    }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actListStudyGuide: "studyGuide/actListStudyGuide",
      actChangeStatusStudyGuide: "studyGuide/actChangeStatusStudyGuide",
      actDeleteStudyGuide: "studyGuide/actDeleteStudyGuide",
      actShowStudyGuide: "studyGuide/actShowStudyGuide",
    }),
    onShowDetails(studyGuideId) {
      this.component = 'StudyGuideShow';
      this.actShowStudyGuide(studyGuideId);
      this.dialog(true);
    },
    onShowEdit(studyGuideId) {
      this.component = 'StudyGuideEdit';
      this.actShowStudyGuide(studyGuideId);
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'StudyGuideCreate';
      this.dialog(true);
    },

    onChangeStatus(item) {
      this.actChangeStatusStudyGuide({
        studyGuideId: item.id,
        payload: {
          active: (item.active != 1)
        }
      }).then(message => {
        this.setShowSnackbar(message);
        this.actListStudyGuide(``);
      })
    },
    submitSearchKeyword() {
      this.actListStudyGuide(`?${query.set('search', this.keyword)}`);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
  created() {
    this.actListStudyGuide(``); // pass emtpy query value for getting all data
  }
}
</script>