<template>
  <div class="p-6" style="min-width: 700px">
       <DialogTitle v-if="!justShow" label="Study Guide" align="center" />
       <Content class="my-3" label="Study Guide ID" :text="studyGuide.guide_id" />
       <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
             <Content class="my-3" label="Section" :text="studyGuide.question_type_section" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="ITEM" :text="studyGuide.question_type_title"/>
            </div>
        </div>
       <div class="my-2">
          <p class="text-uppercase text-victoria mb-2">THE Study Guide</p>
          <div class="text-base line-height-27 rounded bg-wild-sand p-2" v-html="studyGuide.title"></div>
        </div>
       <div class="md-layout md-gutter" v-if="materialLength > 0">
            <div
                class="md-layout-item md-xsmall-size-100 md-large-size-33"
                v-for="(link, index) in studyGuide.materials" :key="index"
            >
                <VideoThumbnail />
            </div>
        </div>
        <div class="mt-4">
            <p class="text-uppercase text-victoria">Important Link</p>
                <div class="my-2" v-for="(link, index) in studyGuide.external_links" :key="index">
                <a class="text-victoria" :href="link">{{ link }}</a>
            </div>
        </div>
        <div v-if="!justShow">
            <Content class="my-3" label="Status" text-color="text-tahiti-gold" :text="(studyGuide.active == '1') ? 'Active' : 'Inactive' "/>
            <div class="flex justify-center">
                <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
                <md-button class="outline-gray-400 bg-victoria text-white text-uppercase rounded">Go to Edit</md-button>
            </div>
        </div>
  </div>
</template>

<script>
import Content from "@/components/atom/Content";
import VideoThumbnail from "@/components/atom/VideoThumbnail";
import { DialogTitle } from "@/components";
import { mapGetters, mapMutations } from "vuex";
export default {
    components: {
        VideoThumbnail,
        DialogTitle,
        Content
    },
    props: {
        justShow: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters({
            studyGuide: "studyGuide/getStudyGuide"
        }),
        materialLength() {
            return this.studyGuide.materials && this.studyGuide.materials.length
        }
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog"
        })
    }
}
</script>