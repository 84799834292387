<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m376 0h-300c-8.284 0-15 6.716-15 15v421c0 8.284 6.716 15 15 15h45v46c0 8.284 6.716 15 15 15h300c8.284 0 15-6.716 15-15v-421c0-8.284-6.716-15-15-15h-45v-46c0-8.284-6.716-15-15-15zm-285 421v-391h270v391zm330-330v391h-270v-31h225c8.284 0 15-6.716 15-15v-345z"/><path d="m316 61h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m256 151c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/><path d="m316 181h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m316 241h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m196 301h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path d="m286 301c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z"/></g></svg>
            <h3 class="pl-2 text-uppercase text-lg">Promp</h3>
          </div>
          <div class="flex">
           <md-button
            v-for="(item, index) in buttonFilters"
            :key="index"
            class="rounded"
            @click="onFilteringData(index, item)"
            :class="[index == currentIndex ? 'bg-victoria text-white': 'outline-gray-400']"
            >
            {{ item.label }}
           </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <!-- <SelectComponent
            :items="types"
            :placeholder="false"
            return-type="object"
            @getObject="getObject"
            class="w-64 m-1" /> -->
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            v-model="keyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="prompts" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="promptId" md-label="PROMPT_ID" class="text-victoria">{{ item.prompt_id }}</md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.question_type_section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.question_type_title }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <md-table-cell >
              <span class="flex action">
              <span
                class="rounded px-1 text-white text-base mx-1"
                :class="[item.active == 1 ? 'bg-victoria' : 'bg-danger']"
                @click="onChangeStatus(item)">{{ (item.active == 1) ? 'Active': 'Inactive' }}
              </span>
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">edit</md-icon>
              <md-icon class="bg-default rounded-full mx-1 p-1 text-gray-700 text-base">delete</md-icon>

              </span>
            </md-table-cell>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8" v-if="prompts.lenght > 20">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox,
    SelectComponent
    } from "@/components";
import { PromptDetails, PromptCreate, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
import { query } from "@/utils/queryString";

export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        PromptCreate,
        SelectComponent
  },
  data() {
      return {
          currentIndex: 0,
          component: 'PromptCreate',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : cms.buttonFilters
      }
  },
  computed: {
    ...mapGetters({
      prompts: "prompt/getPrompts"
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actListPrompt(`?${query.get()}`);
        }
        if(oldvalue.length >= 3) {
          this.actListPrompt(`?${query.get()}`);
        }
    }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actListPrompt: "prompt/actListPrompt",
      actChangePromptStatus: "prompt/actChangePromptStatus",
    }),
    getObject(item) {
      this.onPrepareQueryString('type', this.toLower(item.name));
      this.selectedValue = this.toLower(item.name);
    },
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'PromptCreate';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
   
    onFilteringData(index, item) {
      this.currentIndex = index;
      if(item.name == 'All') {
        query.remove('section');
      }else {
        query.set('section', item.name);
      }
     // this.actListPrompt(`?${query.get()}`);
    },
    onChangeStatus(item) {
      this.actChangePromptStatus({
        promptId: item.id,
        payload: {
          active: (item.active != 1)
        }
      }).then(message => {
        this.setShowSnackbar(message);
        this.actListPrompt()
      })
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
  created() {
    this.actListPrompt()
  }
}
</script>