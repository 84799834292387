<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg version="1.1" class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
            <g><g><path d="M478.309,456.832l-64-128c-2.72-5.408-8.256-8.832-14.304-8.832h-48c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16
                    h38.112l48,96H41.893l48-96h38.112c8.832,0,16-7.168,16-16c0-8.832-7.168-16-16-16h-48c-6.048,0-11.584,3.424-14.304,8.864
                    l-64,128c-2.496,4.96-2.208,10.848,0.704,15.552c2.912,4.704,8.064,7.584,13.6,7.584h448c5.536,0,10.688-2.88,13.632-7.616
                    C480.517,467.68,480.773,461.792,478.309,456.832z"/></g></g><g><g>
                <path d="M367.429,331.776L286.053,34.56C279.941,13.568,261.893,0,240.005,0s-39.968,13.568-46.144,34.816l-81.28,296.96
                    c-0.384,1.376-0.576,2.784-0.576,4.224c0,46.656,114.912,48,128,48c13.088,0,128-1.344,128-48
                    C368.005,334.56,367.813,333.152,367.429,331.776z M240.005,352c-56.096,0-89.824-11.68-95.52-15.616l80.16-292.864
                    C227.749,32.832,237.189,32,240.005,32s12.256,0.832,15.296,11.264l79.776,291.488C326.565,341.408,293.413,352,240.005,352z"/>
            </g>
            </g>
            <g>
            <g>
                <path d="M345.765,252.672c-3.264-8.192-12.576-12.224-20.768-8.96c-38.272,15.2-131.712,15.2-169.952,0
                    c-8.224-3.232-17.472,0.768-20.768,8.96c-3.264,8.192,0.768,17.504,8.96,20.768c22.976,9.12,59.136,14.56,96.768,14.56
                    c37.632,0,73.824-5.44,96.8-14.56C345.029,270.208,349.029,260.896,345.765,252.672z"/>
            </g>
            </g>
            <g>
            <g>
                <path d="M322.213,166.08c-1.952-8.608-10.4-14.016-19.136-12.096c-35.072,7.872-91.104,7.872-126.112,0
                    c-8.704-1.888-17.184,3.488-19.136,12.096c-1.92,8.608,3.488,17.184,12.096,19.136c19.776,4.448,44.032,6.784,70.08,6.784
                    c26.048,0,50.272-2.336,70.08-6.784C318.725,183.264,324.165,174.72,322.213,166.08z"/>
            </g>
            </g>
            <g>
            <g>
                <path d="M297.189,75.808c-1.12-8.8-9.312-15.072-17.856-13.92c-22.624,2.816-56.128,2.784-78.624,0
                    c-8.864-1.024-16.768,5.12-17.856,13.888c-1.088,8.8,5.12,16.768,13.888,17.856C209.317,95.2,223.877,96,240.005,96
                    s30.688-0.8,43.264-2.336C292.069,92.544,298.277,84.576,297.189,75.808z"/>
            </g>
            </g>
        </svg>
            <h3 class="pl-2 text-uppercase text-lg">Iframe Marker</h3>
          </div>
          <div class="flex">
           <md-button
            v-for="(item, index) in buttonFilters"
            :key="index"
            class="rounded"
            @click="onFilteringData(index)"
            :class="[index == currentIndex ? 'bg-victoria text-white': 'outline-gray-400']"
            >
            {{ item.label }}
           </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
          <SelectComponent
            :items="types"
            :placeholder="false"
            return-type="object"
            @getObject="getObject"
            class="w-64 m-1" />
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="item"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="prompts" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="promptId" md-label="PROMPT_ID" class="text-victoria">{{ item.promptId }}</md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="description" md-label="DESCRIPTION">{{ item.description }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">edit</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox,
    SelectComponent
    } from "@/components";
import { PromptDetails, PromptCreate, PromptEdit } from "@/components/molecule";
import { prompts } from "@/data/master/qatm/toolkit"
import { paginate } from "@/mixins/paginate";
import { mapMutations } from "vuex";
import cms from "@/data/cms";

export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        PromptCreate,
        SelectComponent
  },
  data() {
      return {
          currentIndex: 0,
          component: 'PromptCreate',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : [
          {id: 2, label: 'Practice Test'},
          {id: 3, label: 'Mock Test'},
          {id: 4, label: 'Quiz'},
        ]
      }
  },
  computed: {
    prompts() {
      if(this.selectedValue && this.selectedValue !== 'all') {
          return prompts.filter(item => this.toLower(item.section) == this.selectedValue);
      }
      if(this.pagination) {
        return prompts.slice(this.paginate.start, this.paginate.end);
      }
      return prompts;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return prompts.filter(item => this.toLower(item.item).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    getObject(item) {
      this.onPrepareQueryString('type', this.toLower(item.name));
      this.selectedValue = this.toLower(item.name);
    },
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'PromptCreate';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    onFilteringData(index) {
      this.currentIndex = index;
      this.onPrepareQueryString('filter', this.buttonFilters[index].label);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>