<template>
  <div class="outline-gray-400 p-0 rounded flex justify-center align-center">
      <span v-if="link !== null">
        <iframe width="560" height="280" :src="link" frameborder="0" allowfullscreen style="min-height:240px"></iframe>
      </span>
      <span class="flex align-center" v-else style="min-height:240px">
        <b>Empty data</b>
      </span>

  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String
    },
  },
}
</script>

<style>

</style>